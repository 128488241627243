import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MeServiceDto } from 'src/app/shared/classes/me-service-dto';

@Component({
  selector: 'app-me-service-box',
  templateUrl: './me-service-box.component.html',
  styleUrls: ['./me-service-box.component.scss']
})
export class MeServiceBoxComponent {
  @Input() meService: MeServiceDto;
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() loader: boolean = false;

  public ImageSrc : string

  constructor() { }

  ngOnInit(): void {
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
  }


}
