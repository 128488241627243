<div *ngIf="!loader">
  <section class="collection section-b-space">
    <div class="container">
      <ng-container >
        <div class="row partition-collection"><!--xx -->
          <ng-container *ngFor="let item of whyChooseUs">
            <div class="col-lg-3 col-md-6 mb-5" > <!--x4 -->
              <div class="collection-block">
                  <img [src]="item.imageSquare" class="img-fluid" alt="">
                  <div class="collection-content">
                    <h3>{{item.title}}</h3>
                      <h4>{{item.descriptionShort}}</h4>
                      <p>{{item.descriptionComplete}}</p>
                  </div>
              </div>
          </div>
          </ng-container>
     </div>
      </ng-container>
    </div>
</section>
  </div>
  <app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
  