import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor() { }

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			title: 'home',  	active: false, 		path: '/home/fashion', 			type: 'link'
		},
		// {
		// 	title: 'services', 	active: false,   	path: '/pages/services',  		type: 'link'
		// },
		// {
		// 	title: 'Portafolio',megaMenu: true, badge: true, badgeText: 'new', active: false,  path: '/pages/portfolio/grid/two', type: 'link'
		// },
		{
			title: 'contact', 	active: false,   	path: '/pages/contact',  		type: 'link'
		},
		{
			title: 'about-us', 	active: false,		path: '/pages/aboutus',			type: 'link'
		}//,
		// {
		// 	title: 'blogs',		active: false, 		path: '/pages/blog/left/sidebar', type: 'link'
		// }
	];

	LEFTMENUITEMS: Menu[] = [
		{
			path: '/home/fashion', title: 'home', type: 'link'
		},
		// {
		// 	path: '/pages/services', title: 'services', type: 'link'
		// },
		// {
		// 	path: '/pages/portfolio/grid/two', title: 'portafolio', type: 'link'
		// },
		{
			path: '/pages/contact', title: 'contact', type: 'link'
		},
		{
			path: '/pages/aboutus', title: 'about us', type: 'link'
		}//,
		// {
		// 	path: '/pages/blog/left/sidebar', title: 'blog', type: 'link'
		// }
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);

}
