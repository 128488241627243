<div *ngIf="!loader">
    <div class="img-wrapper">
      <div class="front" id="border-ds-box">
        <a [routerLink]="[meService?.link]" class="solidborder border-black">
          <img 
            [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
            [lazyLoad]="ImageSrc ? ImageSrc : meService.imageSquare" 
            class="img-fluid lazy-loading" 
            alt="" />
        </a>
      </div>
      <div class="back" *ngIf="onHowerChangeImage">
        <h4>{{ meService?.descriptionComplete }}</h4>
      </div>
    </div>
    <div class="product-detail">
      <div>
        <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
        <a [routerLink]="[meService?.link]">
          <h6>{{ meService?.title | titlecase }}</h6>
        </a>
        <h4>
          {{ meService?.descriptionShort }}
        </h4>
      </div>
    </div>
  </div>
  <app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
  