import { Component, Input } from '@angular/core';
import { WhyChooseUsDto } from 'src/app/shared/classes/why-choose-us-dto';

@Component({
  selector: 'app-why-choose-us-box',
  templateUrl: './why-choose-us-box.component.html',
  styleUrls: ['./why-choose-us-box.component.scss']
})
export class WhyChooseUsBoxComponent {
  @Input() whyChooseUs: WhyChooseUsDto[];
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() loader: boolean = false;

  public ImageSrc : string

  constructor() { }

  ngOnInit(): void {
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
  }

}
