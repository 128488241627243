<!--footer section -->
<footer [class]="class">
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo">
						</div>
						<p>At DsAlpha, we transform visions into technological realities through industry-leading software solutions and consultancy. We are committed to exceeding expectations and setting new standards for excellence, innovation, and sustainability. We are your bridge to a more efficient and connected future.</p>
						<div class="footer-social">
                            <ul>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-google-plus" aria-hidden="true"></i></a>                 
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-rss" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>Services</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="javascript:void(0)">Consulting Services</a></li>
                                <li><a href="javascript:void(0)">Software Architecture Design</a></li>
                                <li><a href="javascript:void(0)">Frontend Development</a></li>
                                <li><a href="javascript:void(0)">Backend Development</a></li>
                                <li><a href="javascript:void(0)">Utility NuGet Package Creation</a></li>
                                <li><a href="javascript:void(0)">Functionality Repair & Optimization</a></li>
                                <li><a href="javascript:void(0)">Custom Software Extensions</a></li>
                                <li><a href="javascript:void(0)">Database Design and Implementation</a></li>
                                <li><a href="javascript:void(0)">DevOps Configuration</a></li>
                                <li><a href="javascript:void(0)">Solutions Architecture</a></li>
                                <li><a href="javascript:void(0)">Microservices Solutions</a></li>
                                <li><a href="javascript:void(0)">Server Configuration & Management</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>why choose us</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="javascript:void(0)">Expertise</a></li>
                                <li><a href="javascript:void(0)">Quality Assurance</a></li>
                                <li><a href="javascript:void(0)">Innovation</a></li>
                                <li><a href="javascript:void(0)">Reliability</a></li>
                                <li><a href="javascript:void(0)">Scalability</a></li>
                                <li><a href="javascript:void(0)">Efficiency</a></li>
                                <li><a href="javascript:void(0)">Full-Stack Capabilities</a></li>
                                <li><a href="javascript:void(0)">Database Expertise</a></li>
                                <li><a href="javascript:void(0)">Client-Centric</a></li>
                                <li><a href="javascript:void(0)">Project Management</a></li>
                                <li><a href="javascript:void(0)">Sustainability</a></li>
                                <li><a href="javascript:void(0)">Global Perspective</a></li>
                                <li><a href="javascript:void(0)">Ethical</a></li>
                                <li><a href="javascript:void(0)">Community-Oriented</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
						    <h4>information</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
						        <li><i class="fa fa-map-marker"></i>DsAlpha, 
						            Quito - El Batan</li>
						        <li><i class="fa fa-phone"></i>Call Us: +593 98 350 2183</li>
						        <li><i class="fa fa-envelope-o"></i>Email Us: <a>dmse.593@gmail.com</a></li>
						        <!-- <li><i class="fa fa-fax"></i>Fax: 123456</li> -->
						    </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} DsAlpha - From Vision to Reality</p>
                  </div>
                </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->